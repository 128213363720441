class AuthService {

    async sendPhoneConfirmation(phoneNumber, type) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Accept': 'application/json'
        }
      };

      const response = await fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/send/phone/token/anonymous?PhoneNumber=${encodeURIComponent(phoneNumber)}&Purpose=${type}`, requestOptions);
      return response;
    }

    async registration(form) {
        const requestOptions = {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(form)
          };

          return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/signup`, requestOptions)
            .then(res => res.json());
    }

    async sendResetPasswordEmail(email) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Accept': 'application/json'
        }
      };

      const response = await fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/send/email/password/reset?Email=${email}`, requestOptions);
      if (response.status !== 200) {
        throw Error("");
      }
    }

    async resetPassword(form) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
        },
        body: JSON.stringify(form)
      };

      return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/reset/password`, requestOptions)
        .then(res => res.json());
    }

    async signin(form) {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', 
        },
        credentials: 'include',
        body: JSON.stringify(form)
      };
      return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/signin/`, requestOptions)
        .then(res => res.json())
        .then((response) => {
          if (response.isOk) {
            window.location.replace(response.returnUrl);
            return true;
          }
          else {
            return false;
          }
        })
    }

    async logout(logoutId) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                logoutId: logoutId,
            }),
            credentials: 'include'
        };
        return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/logout/`, requestOptions)
            .then(res => res.json());
    }

    async checkPhoneAvailability(phone) {
      const requestOptions = {
        method: 'GET'
      };



      return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/availability/phone?PhoneNumber=${encodeURIComponent(phone)}`, requestOptions)
        .then(res => res.json());
    }

    async checkEmailAvailability(email) {
      const requestOptions = {
        method: 'GET'
      };
      return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/availability/email?Email=${encodeURIComponent(email)}`, requestOptions)
        .then(res => res.json());
    }
    async confirmEmail(form) {
      const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(form)
      };

      const response = await fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/confirm/email`, requestOptions);

      if (response.status !== 200) {
        throw 'Произошла ошибка при попытке подтверждения почты';
      }

      const result = await response.json();
      
      //if response from identity
      if (result.isOk === false){
        throw result.message;
      }

      //if response from client portal
      if (result.IsOk === false) {
        throw result.Message;
      }

      return result;
    }
}

export default new AuthService();