import React, { useState } from 'react'
import { Typography, Button, TextField, FormGroup, FormHelperText, Link } from '@mui/material'
import InputMask from 'react-input-mask'
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";

import { emailValidationRegex, phoneValidationRegex } from '../../constants/validation_rules';
import { PhoneTokenInputModal } from "../authentication/phone_token_input_modal";
import { phone_number_validation_rule, email_validation_rule, password_validation_rule } from '../../constants/validation_rules';
import { phoneTokenPurposeTypes } from '../../constants/phone_token_purpose_types';
import AuthService from '../../services/authService'; 

export const Signup = () => {
    const [showPhoneTokenInputModal, setShowPhoneTokenInputModal] = useState(false);
    const [inputValues, setInputValues] = useState(null);
    const [serverErrors, setServerErrors] = useState([]);
    const [searchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleRegistration = async (formValues) => {
        setServerErrors([]);
        return AuthService.registration(formValues)
            .then((result) => {
                if (result.isOk !== true) {
                    setServerErrors([result.message]);
                }
                else {
                    //enqueueSnackbar('Пользователь успешно зарегистрирован', { variant: "success" });
                    navigate({
                        pathname: '/signup-success',
                        search: `?${searchParams}`
                    });
                }
            })
            .catch(() => {
                setServerErrors(['Произошла ошибка при попытке регистрации']);
            });
    }

    const validationSchema = Yup.object({
        phone: phone_number_validation_rule
            .required('Обязательное поле')
            .test('checkPhoneAvailable', 'Номер телефона уже зарегистрирован в системе', async (value) => {
                if (!value.match(phoneValidationRegex)) {
                    return true;
                }
                const result = await AuthService.checkPhoneAvailability(value);
                return result.isOk;
            }),
        email: email_validation_rule
            .required('Обязательное поле')
            .test('checkEmailAvailable', 'Адрес электронной почты уже зарегистрирован в системе', async (value) => {
                if (!value.match(emailValidationRegex)) {
                    return true;
                }
                const result = await AuthService.checkEmailAvailability(value);
                return result.isOk;
            }),
        password: password_validation_rule
            .required('Обязательное поле'),
        confirmPassword: password_validation_rule
            .oneOf([Yup.ref('password')], 'Введенные пароли не совпадают')
            .required('Обязательное поле')
    });

    const handleInputToken = async (phoneVerificationToken) => {
        const request = {...inputValues, phoneVerificationToken};
        return handleRegistration(request)
            .then(() => {
                setShowPhoneTokenInputModal(false);
            })
    }

    const handleSendToken = async (phoneNumber) => {
        return AuthService.sendPhoneConfirmation(phoneNumber, phoneTokenPurposeTypes.forPhoneConfirmation)
            .then(async (result) => {
                if (result.status === 429) {
                    setShowPhoneTokenInputModal(true);
                    enqueueSnackbar(`Не удалось отправить смс, на этот номер уже недавно было отправлено сообщение`, { variant: "error" });
                    return;
                }
                var json = await result.json();
                if (json.isOk !== true) {
                    enqueueSnackbar(`Произошла ошибка при отправке смс, ${json.message}`, { variant: "error" });
                } else {
                    setShowPhoneTokenInputModal(true);
                    enqueueSnackbar('Успешно отправлено смс с кодом', { variant: "success" });
                }
            })
            .catch(() => {
                enqueueSnackbar(`Произошла ошибка при отправке смс`, { variant: "error" });
            });
    }

    const handleModalClose = () => {
        setShowPhoneTokenInputModal(false);
    }

    const standartifyPhoneNumber = (phoneNumber) => {
        if (phoneNumber[0] === '8' || phoneNumber[0] === '7') {
            phoneNumber='+7' + phoneNumber.substring(1);
        }
    
        return phoneNumber
            .replaceAll(/[^+\d]/g, "")
    }

    return (
        <React.Fragment>
            <PhoneTokenInputModal 
                open={showPhoneTokenInputModal} 
                inputToken={handleInputToken} 
                resendToken={() => handleSendToken(inputValues.phone)} 
                onClose={handleModalClose} 
            />
                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>
                <Formik 
                    initialValues={{phone: '', email: '', password: '', confirmPassword: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setInputValues(values);
                        handleSendToken(values.phone)
                            .then(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                        <Field name="phone">
                            {({field, form, meta}) => (
                                <FormGroup>
                                    <InputMask
                                        {...field}
                                        id="login_phone"
                                        mask="+7 999 999 9999"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            if (value){
                                                formik.setFieldValue('phone', standartifyPhoneNumber(value));
                                            }
                                            else {
                                                formik.setFieldValue('phone', '');
                                            }
                                        }}
                                        beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
                                            if (nextState.enteredString) {
                                                var standartifiedPhoneNumber = standartifyPhoneNumber(nextState.enteredString);
                                                if (standartifiedPhoneNumber.match(phoneValidationRegex)) {
                                                    
                                                    const result = {
                                                        ...nextState,
                                                        value: standartifiedPhoneNumber
                                                    };
                                                    return result; 
                                                }
                                            }
                    
                                            //if pasted full phone number set cursor to the end of string
                                            if (standartifyPhoneNumber(nextState.value).match(phoneValidationRegex)){
                                                nextState.selection = {
                                                    start: 15,
                                                    end: 15,
                                                    length: 0
                                                }
                                            }
                    
                                            return { ...nextState}; 
                                        }}
                                    >
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="phone"
                                            label="Номер телефона"
                                            name="phone"
                                            helperText={meta.touched && meta.error}
                                            error={ meta.touched && meta.error != null }
                                        />
                                    </InputMask>
                                </FormGroup>
                            )}
                        </Field>

                        <Field name="email" >
                            {({field, form, meta}) => (
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        {...formik.getFieldProps('email')}
                                        helperText={meta.touched && meta.error}
                                        error={meta.touched  && meta.error != null}
                                    />
                                </FormGroup>
                            )}
                        </Field>

                        <Field name="password" >
                            {({field, form, meta}) => (
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        type="password"
                                        id="password"
                                        label="Пароль"
                                        name="password"
                                        autoComplete="new-password"
                                        {...formik.getFieldProps('password')}
                                        error={meta.touched  && meta.error != null}
                                        helperText={meta.touched && meta.error}
                                    />
                                </FormGroup>
                            )}
                        </Field>

                        <Field name="confirmPassword" >
                            {({field, form, meta}) => (
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        type="password"
                                        id="confirmPassword"
                                        label="Подтверждение пароля"
                                        name="confirmPassword"
                                        autoComplete="new-password-confirm"
                                        {...formik.getFieldProps('confirmPassword')}
                                        error={meta.touched  && meta.error != null}
                                        helperText={meta.touched && meta.error}
                                    />
                                </FormGroup>
                            )}
                        </Field>

                        <FormHelperText style={{ textAlign: "justify" }}>
                            Нажимая на кнопку “Зарегистрироваться”, вы соглашаетесь с <Link href="/personal-data-policy.html" target="_blank">Политикой обработки персональных данных</Link>
                        </FormHelperText>

                        {/* server errors */}
                        { serverErrors.length > 0 
                            ? 
                                serverErrors.map((error, key) => 
                                    <FormHelperText error key={key}>{error}</FormHelperText>
                                )   
                            : null
                        }

                        <Button
                            type="submit"
                            disabled={formik.isSubmitting}
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2}}
                        >
                            Зарегистрироваться
                        </Button>

                        <Button
                            type="button"
                            variant="text"
                            fullWidth
                            sx={{ mt: 1}}
                            onClick={() => navigate({
                                pathname: '/signin',
                                search: `?${searchParams}`
                            })}
                        >
                            Вернуться
                        </Button>

                    </Form>
                )}
                </Formik>
		</React.Fragment>
    )
}
